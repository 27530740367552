import { SPopupBase } from '@/store/base'
import { Vue, Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const base = namespace('base')

@Component
export default class PopupMixin extends Vue {
  @base.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void

  handleOpenPopup(data: SPopupBase) {
    this.setOpenPopup(data)
  }
}
