
  import { Component, Vue } from 'vue-property-decorator'

  @Component({ components: {} })
  export default class MemberPopup extends Vue {
    activeName = 'first'

    checkedCourse = []
    checkedMember = []

    handleOpen() {
      console.log('open')
    }

    handleClose() {
      console.log('close')
    }

    async handleSubmit() {
      this.$emit('submit')
    }
  }
