
  import { Component, Mixins } from 'vue-property-decorator'
  import MemberPopup from './MemberPopup.vue'
  import PopupMixin from '@/mixins/popupMixin'

  @Component({ components: { MemberPopup } })
  export default class MemberPicker extends Mixins(PopupMixin) {
    tags = [
      { name: 'Tag 1', type: '' },
      { name: 'Tag 2', type: 'success' },
      { name: 'Tag 3', type: 'info' },
      { name: 'Tag 4', type: 'warning' },
      { name: 'Tag 5', type: 'danger' }
    ]
    openAddPopup() {
      this.handleOpenPopup({
        module: 'meetingMemberPopup',
        isOpen: true
      })
    }
    handleClose(tag: any) {
      this.tags.splice(this.tags.indexOf(tag), 1)
    }

    handleSubmit() {
      this.handleOpenPopup({
        module: 'meetingMemberPopup',
        isOpen: false
      })
    }
  }
