
  import { Component, Vue } from 'vue-property-decorator'
  import { dayOfWeek } from '@/config'
  import MemberPicker from '../member/MemberPicker.vue'

  @Component({ components: { MemberPicker } })
  export default class CreateMetting extends Vue {
    data: Record<string, any> = {
      count: 20,
      time: new Date()
    }

    checked = false

    recurrence = 'daly'
    weeklyLoop = []
    timeZone = []

    customWeeklyLoop: Record<string, any> = {
      default: this.data.time
    }

    options = [
      { label: 'Daly', value: 'daly' },
      { label: 'Weekly', value: 'weekly' },
      // { label: 'Monthly', value: 'monthly' },
      { label: 'Custom weekly', value: 'custom' }
    ]
    dayOfWeek = dayOfWeek

    pickerOptions = {
      disabledDate(time: Date) {
        return time.getTime() < Date.now() - 1000 * 60 * 60 * 24
      },
      shortcuts: [
        {
          text: 'Today',
          onClick(picker: any) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: 'Yesterday',
          onClick(picker: any) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        },
        {
          text: 'A week ago',
          onClick(picker: any) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }
      ]
    }

    addMore() {
      const key =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
      console.log(key)
      this.customWeeklyLoop[key] = new Date()
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }

    reset() {
      this.data = {}
    }

    handleOpen() {
      console.log('open')
    }

    handleClose() {
      this.reset()
    }

    handleSubmit() {
      console.log('submit')
    }
  }
